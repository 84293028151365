import { BzProduct, FilterSearchPage, ICurrentFilter, IPreparatPageResponse, ITableCatalogProductsResponse, IToSearchWithCategory, ITradeGoodsResponse, Options } from '@interfaces';
import { IEditor, IProduct, PermisionsProduct } from 'src/app/shared/interfaces/product.model';

export interface SearchState {
    loading: boolean;
    target: string;
    filtersForTarget?: any[];
    options: Options[];
    found: any[];
    custom: any[];
    filtersForFound?: Map<string, FilterSearchPage[]>;
    activeFilters: number;
    resetFilterBuffer: boolean;
    page: number[];
    showMore: boolean;
    totalGoodsNumber: number;
    pageNumber: number;
    popupContent: string | {data: BzProduct[]; product: IProduct} | PermisionsProduct[];
    currentFilters: ICurrentFilter[];
    references: IToSearchWithCategory[];
    h1?: string;
    seo_description?: string;
    seo_text?: string;
    seo_title?: string;
    tradeNameTitle?: string;
    seoTextUnderPagination?: string | null;
    seoFaq?: ITradeGoodsResponse | null;
    seoTableWithPrices?: ITableCatalogProductsResponse | null;
    seoAnalogs?: BzProduct[] | null;
    seoEditor?: IEditor | null;
    preparatPageSeoData?: IPreparatPageResponse | null;
}

export const initialSearchState: SearchState = {
    loading: false,
    target: null,
    options: [],
    found: [],
    custom: [],
    filtersForFound: new Map(),
    activeFilters: 0,
    resetFilterBuffer: false,
    page: [1, 1],
    showMore: false,
    totalGoodsNumber: 0,
    pageNumber: 0,
    popupContent: null,
    filtersForTarget: [],
    currentFilters: [],
    references: null,
    h1: null,
    seo_description: null,
    seo_text: null,
    seo_title: null,
    tradeNameTitle: '',
    seoTextUnderPagination: null,
    seoFaq: null,
    seoTableWithPrices: null,
    seoAnalogs: null,
    seoEditor: null,
    preparatPageSeoData: null,
};
