import { BzProduct, FilterSearchPage, ICurrentFilter, IPreparatPageResponse, ISeoData, ITableCatalogProductsResponse, IToSearchWithCategory, ITradeGoodsResponse, Options } from '@interfaces';
import { createSelector } from '@ngrx/store';
import { IEditor, IProduct, PermisionsProduct } from 'src/app/shared/interfaces/product.model';
import { AppState } from '@appStates';
import { SearchState } from '@states';

const selectSearchState = (state: AppState): SearchState => state.search;

export const selectListoptions = createSelector(
    selectSearchState,
    (state: SearchState): Options[] => state.options
);

export const selectTarget = createSelector(
    selectSearchState,
    (state: SearchState): string => state.target
);

export const selectFounds = createSelector(
    selectSearchState,
    (state: SearchState): BzProduct[] => {
      const map: Map<number, BzProduct> = new Map();
      state.found.forEach(product => map.set(product.id, product));
      return Array.from(map.values());
    }
);

export const selectSeoData = createSelector(
  selectSearchState,
  (state: SearchState): ISeoData => {
    return {
      h1: state.h1,
      seo_description: state.seo_description,
      seo_text: state.seo_text,
      seo_title: state.seo_title
    };
  }
);

export const selectFoundsLength = createSelector(
    selectSearchState,
    (state: SearchState): number => (state.found ?? []).length
);

export const selectFoundsFiltersPage = createSelector(
    selectSearchState,
    (state: SearchState): Map<string, FilterSearchPage[]> => new Map(Object.entries(state.filtersForFound))
);

export const activeFiltersPage = createSelector(
    selectSearchState,
    (state: SearchState): Map<number, string> => {
        const res: Map<number, string> = new Map();

        return res;
    }
);

export const selectPageCount = createSelector(
  selectSearchState,
  (state: SearchState): number[] => state.page
);

export const selectPageEnd = createSelector(
  selectSearchState,
  (state: SearchState): number => state.page[1]
);

export const selectShowMore = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.showMore
);

export const selectTradeTitleForListPage = createSelector(
  selectSearchState,
  (state: SearchState): string => state.tradeNameTitle
);

export const selectSeoAnalogsForListPage = createSelector(
  selectSearchState,
  (state: SearchState): BzProduct[] => state.seoAnalogs
);

export const selectSeoTextForThirdLvlListPage = createSelector(
  selectSearchState,
  (state: SearchState): string => state.seoTextUnderPagination
);

export const selectTableWithPricesForListPage = createSelector(
  selectSearchState,
  (state: SearchState): ITableCatalogProductsResponse => state.seoTableWithPrices
);

export const selectSeoFaqForListPage = createSelector(
  selectSearchState,
  (state: SearchState): ITradeGoodsResponse => state.seoFaq
);

export const selectCountActiveFilters = createSelector(
    selectSearchState,
    (state: SearchState): number => {
        return state.activeFilters;
    }
);

export const seletStatusResetBufferFilter = createSelector(
    selectSearchState,
    (state: SearchState): boolean => {
        return state.resetFilterBuffer;
    }
);

export const selecttotalGoodsNumber = createSelector(
    selectSearchState,
    (state: SearchState): number => state.totalGoodsNumber - 0.1 // Fix for ngx-pagination
);

export const selectCurrentFilters = createSelector(
    selectSearchState,
    (state: SearchState): ICurrentFilter[] => {
        return state.currentFilters;
    }
);

export const selectCurrentFiltersKeys = createSelector(
    selectSearchState,
    (state: SearchState): Set<string> => {
        const filtersArray: ICurrentFilter[] = state.currentFilters;
        const filtersKeysSet: Set<string> = new Set();
        filtersArray.forEach((element: ICurrentFilter) => filtersKeysSet.add(element.group));
        return filtersKeysSet;
    }
);

export const selectCurrentFiltersLength = createSelector(
    selectSearchState,
    (state: SearchState): number => {
        return (state.currentFilters ?? []).length;
    }
);

export const selectorContentPopup = createSelector(
    selectSearchState,
    (state: SearchState): string | { data: BzProduct[]; product: IProduct } | PermisionsProduct[] =>
        state.popupContent
);

export const selectReferences = createSelector(
    selectSearchState,
    (state: SearchState): IToSearchWithCategory[] => state.references
);

export const selectPageLoadingState = createSelector(
  selectSearchState,
  (state: SearchState): boolean => state.loading
);

export const selectSeoEditorState = createSelector(
  selectSearchState,
  (state: SearchState): IEditor => state.seoEditor
);

export const selectPreparatSeoData = createSelector(
  selectSearchState,
  (state: SearchState): IPreparatPageResponse => state.preparatPageSeoData
);