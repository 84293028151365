import { Action, createReducer, on } from '@ngrx/store';
import { initialSearchState, SearchState } from '@states';

import * as SearchActions from '@actions';
import { BzProduct, ICurrentFilter } from '@interfaces';

const reducer = createReducer(
  initialSearchState,
  on(
    SearchActions.SetTargetSuccess, (state, action) => ({
      ...state,
      target: action.target
    })
  ),
  on(
    SearchActions.GetOptionsSuccess, (state, action) => ({
      ...state,
      options: action.options
    })
  ),
  on(
    SearchActions.ClearOptionsSuccess, (state) => ({
      ...state,
      options: []
    })
  ),
  on(
    SearchActions.SetH1ForSearchListPageAction, (state, action) => ({
      ...state,
      tradeNameTitle: action.title
    })
  ),
  on(
    SearchActions.SetSeoAnalogsForListPageAction, (state, { analogs }) => ({
      ...state,
      seoAnalogs: analogs
    })
  ),
  on(
    SearchActions.SetSeoTextWithEditorForThirdLvlAction, (state, { textWithEditor }) => ({
      ...state,
      seoTextUnderPagination: textWithEditor?.text,
      seoEditor: textWithEditor?.editor,
    })
  ),
  on(
    SearchActions.SetFaqForListPageAction, (state, { faq }) => ({
      ...state,
      seoFaq: faq
    })
  ),
  on(
    SearchActions.SetTableForListPageAction, (state, { table }) => ({
      ...state,
      seoTableWithPrices: table
    })
  ),
  on(
    SearchActions.GetFoundSuccess, (state, action) => {
      return action?.found ? ({
        ...state,
        found: action.showMore ? [...state.found, ...(action.found?.goods || [])] : (action.found?.goods || []),
        pageNumber: action.found?.pageNumber,
        totalGoodsNumber: action.found?.totalGoodsNumber,
        references: action.found?.references,
        h1: action.found?.h1,
        seo_description: action.found?.seo_description,
        seo_text: action.found?.seo_text,
        seo_title: action.found?.seo_title
      }) : ({
        ...state
      });
    }
  ),
  on(
    SearchActions.ResetPageStateAction, (state) => {
      return ({
        ...state,
        found: [],
        h1: null,
        references: [],
        pageNumber: 0,
        totalGoodsNumber: 0,
        seo_description: null,
        seo_text: null,
        seo_title: null,
        tradeNameTitle: '',
        seoTextUnderPagination: null,
        seoEditor: null,
        preparatPageSeoData: null,
      });
    }
  ),
  on(
    SearchActions.ClearFoundActionSuccess, (state) => ({
      ...state,
      found: [],
      references: [],
      totalGoodsNumber: 0,
      filtersForFound: new Map()
    })
  ),
  on(
    SearchActions.ActiveFiltersSuccess, (state, action) => ({
      ...state,
      activeFilters: action.count
    })
  ),
  on(
    SearchActions.ResetFilterBufferAction, (state, action) => ({
      ...state,
      resetFilterBuffer: action.status
    })
  ),
  on(
    SearchActions.AddPageCountLoadDataAction, (state, action) => ({
      ...state,
      page: action.pages ? action.pages : [state.page[0] + 1, state.page[1] + 1]
    })
  ),
  on(
    SearchActions.ResetPageCountLoadDataAction, (state) => ({
      ...state,
      page: [1, 1]
    })
  ),
  on(
    SearchActions.ShowMoreLoadDataAction, (state, action) => ({
      ...state,
      showMore: action.load
    })
  ),
  on(
    SearchActions.AddInstructionForPopupAction, (state, action) => ({
      ...state,
      popupContent: action.instruction
    })
  ),
  on(
    SearchActions.AddAnalogsForPopupAction, (state, action) => ({
      ...state,
      popupContent: action.analogs
    })
  ),
  on(
    SearchActions.AddPermissionForPopupAction, (state, action) => ({
      ...state,
      popupContent: action.permission
    })
  ),
  on(
    SearchActions.LoadFiltersPageSuccess, (state, action) => {
      return ({
        ...state,
        filtersForFound: action.filters
      })
    }

  ),
  on(
    SearchActions.AddProductInListAction, (state, { product }) => {
      const productId = (state.seoAnalogs ?? []).findIndex((item: BzProduct) => item.id === product);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        seoAnalogs: Object.assign([], {
          ...state.seoAnalogs,
          [productId]: {
            ...state.seoAnalogs[productId],
            inList: !state.seoAnalogs[productId].inList
          }
        })
      });
    }
  ),
  on(
    SearchActions.AddProductInFavoriteAction, (state, { id }) => {
      const productId = (state.seoAnalogs ?? []).findIndex((item: BzProduct) => item.id === id);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        seoAnalogs: Object.assign([], {
          ...state.seoAnalogs,
          [productId]: {
            ...state.seoAnalogs[productId],
            isFavorite: !state.seoAnalogs[productId].isFavorite
          }
        })
      })
    }
  ),
  on(
    SearchActions.AddProductInListAction, (state, action) => {
      const productId = state.found.findIndex((item: BzProduct) => item.id == action.product);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        found: Object.assign([], {
          ...state.found,
          [productId]: {
            ...state.found[productId],
            inList: !state.found[productId].inList
          }
        })
      })
    }
  ),
  on(
    SearchActions.AddProductInFavoriteAction, (state, action) => {
      const productId = state.found.findIndex((item: BzProduct) => item.id == action.id);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        found: Object.assign([], {
          ...state.found,
          [productId]: {
            ...state.found[productId],
            isFavorite: !state.found[productId].isFavorite
          }
        })
      })
    }
  ),
  on(
    SearchActions.DeleteProductsFromListAction, (state) => {
      let productsArray = [...state.found].map(product => ({ ...product, inList: false }))
      return ({
        ...state,
        found: productsArray
      })
    }
  ),
  on(
    SearchActions.DeleteProductsFromListAction, (state) => {
      let productsArray = [...(state.seoAnalogs ?? [])].map(product => ({ ...product, inList: false }))
      return ({
        ...state,
        seoAnalogs: productsArray
      })
    }
  ),
  on(
    SearchActions.DeleteOneProductFromListAction, (state, action) => {
      const productId = state.found.findIndex((item: BzProduct) => item.id == action.product);
      return ({
        ...state,
        found: Object.assign([], {
          ...state.found,
          [productId]: {
            ...state.found[productId],
            inList: false
          }
        })
      })
    }
  ),
  on(
    SearchActions.DeleteOneProductFromListAction, (state, { product }) => {
      const productId = (state.seoAnalogs ?? []).findIndex((item: BzProduct) => item.id === product);
      if (productId === -1) return ({ ...state });
      return ({
        ...state,
        seoAnalogs: Object.assign([], {
          ...state.seoAnalogs,
          [productId]: {
            ...state.seoAnalogs[productId],
            inList: false
          }
        })
      })
    }
  ),
  on(
    SearchActions.DeleteProductFromFavoriteAction, (state, action) => {
      const productId = state.found.findIndex((item: BzProduct) => item.id == action.product);
      return ({
        ...state,
        found: Object.assign([], {
          ...state.found,
          [productId]: {
            ...state.found[productId],
            isFavorite: false
          }
        })
      })
    }
  ),
  on(
    SearchActions.SetCurrentFiltersAction, (state, action) => {
      return ({
        ...state,
        currentFilters: action.current
      })
    }
  ),
  on(
    SearchActions.DeleteOneCurrentFilterAction, (state, action) => {
      const result: any[] = [];
      state.currentFilters.forEach((element: ICurrentFilter) => {
        if (element.id !== action.id) {
          result.push(element)
        }
      })
      return ({
        ...state,
        currentFilters: result
      })
    }
  ),
  on(
    SearchActions.SetPageLoadingAction, (state, { loading }) => {
      return ({
        ...state,
        loading,
      })
    }
  ),
  on(
    SearchActions.SetPreparatSeoDataAction, (state, { seoData }) => ({
      ...state,
      preparatPageSeoData: seoData,
    })
  ),
  on(
    SearchActions.TogglePreparatPharmacyProducts, (state, { pharmacy }) => ({
      ...state,
      preparatPageSeoData: {
        ...state.preparatPageSeoData, exists: state.preparatPageSeoData.exists.map((pharm) => {
          if (pharm.id === pharmacy.id) {
            return {
              ...pharm,
              isExpanded: !pharmacy.isExpanded
            }
          } else {
            return {
              ...pharm,
            }
          }
        })
      },
    })
  )
);

export function searchReducer(state: SearchState | undefined, action: Action): SearchState {
  return reducer(state, action);
}
