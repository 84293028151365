import { Action, createReducer, on } from '@ngrx/store';
import { adapterActiveMobileFilters, adapterListMarkers, initialStatePharmaciesPage, PharmaciesPageState } from '@states';
import * as PharmesActions from '@actions';
import { Update } from '@ngrx/entity';
import { BzApteka, BzProduct, PharmacyMarker } from '@interfaces';

const reducer = createReducer(
  initialStatePharmaciesPage,
  on(
    PharmesActions.IsTargetFromProductCardGetOtherCityPharmaciesAction, (state, action) =>
      {
        return ({
        ...state,
          isTargetFromProductCardGetOtherCityPharmacies: action.other
      });
    }
  ),
  on(
    PharmesActions.isGetOtherCityPharmaciesAction, (state, action) =>
      {
        return ({
        ...state,
          isGetOtherCityPharmacies: action.other
      });
    }
  ),
  on(
    PharmesActions.SwitchMobilePageMapAction, (state, action) =>
      {
        return ({
        ...state,
          isActiveMobilePageMap: action.active
      });
    }
  ),
  on(
    PharmesActions.isLoadingListPharmaciesAction, (state, action) =>
      {
        return ({
        ...state,
        isLoadingListPharmacies: action.loading
      });
    }
  ),
  on(
    PharmesActions.DeleteToTopInListPharmaciesAction, (state, action) =>
      {
        return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(action.id, {
            ...state.listPharmacies.get(action.id),
            toTop: false
          })
        ])
      });
    }
  ),
  on(
    PharmesActions.ClearAllToTopInListPharmaciesAction, (state) =>
      {
        return ({
        ...state,
        listPharmacies: new Map([...state.listPharmacies].map(([id, pharma]) => [id, { ...pharma, toTop: false }]))
      });
    }
  ),
  on(
    PharmesActions.setTotalPharmaciesLengthAction, (state, action) => {
      return ({
        ...state,
        totalPharmaciesNumber: action.total
      });
    }
  ),
  on(
    PharmesActions.setTotalOtherPharmaciesLengthAction, (state, action) => {
      return ({
        ...state,
        totalOtherPharmacies: action.total
      });
    }
  ),
  on(
    PharmesActions.LoadListPharmaciesAction, (state, action) => {
      if (action.pharmacies.size === 1 && state.listPharmacies.has(Array.from(action.pharmacies)[0][0])) {
        return ({
          ...state
        });
      } else {
        return ({
          ...state,
          listPharmacies: new Map([...state.listPharmacies, ...action.pharmacies])
        });
      }
    }

  ),
  on(

    PharmesActions.ClearListPharmaciesAction, (state) => ({
      ...state,
      listPharmacies: new Map()
    })

  ),
  on(

    PharmesActions.SwitchInfoOnePharmcyAction, (state, action) => {
      return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(action.id, {
            ...state.listPharmacies.get(action.id),
            isOpenInfo: action.value
          })
        ])
      });
    }

  ),
  on(

    PharmesActions.LoadListMarkersAction, (state, action) => ({
      ...state,
      listMarkers: adapterListMarkers.setAll(action.markers, state.listMarkers)
    })

  ),
  on(
    PharmesActions.UpdateCartMarkerAction, (state, action) => {
      const markUpdate: Update<PharmacyMarker> = {
        id: action.markerID,
        changes: { cart: action.value }
      };
      return ({
      ...state,
      listMarkers: adapterListMarkers.updateOne(markUpdate, state.listMarkers)
    })}
    ),
  on(
    PharmesActions.SelectedCartMarkerAction, (state, action) => {
      const updatesMarkers: Update<PharmacyMarker>[] = Object.values(state.listMarkers.entities).map(marker => {
        return { id: marker.id, changes: { selected: action.id === marker.id } };
      });
      return ({
      ...state,
      listMarkers: adapterListMarkers.updateMany(updatesMarkers, state.listMarkers)
    })}
    ),
  on(
    PharmesActions.DisableCartMarkersAction, (state) => {
      const updatesMarkers: Update<PharmacyMarker>[] = Object.values(state.listMarkers.entities).map(marker => {
        return { id: marker.id, changes: { cart: false } };
      });
      return ({
      ...state,
        listMarkers: adapterListMarkers.updateMany(updatesMarkers, state.listMarkers)
    })}
    ),
  on(

    PharmesActions.SetCenterMapAction, (state, action) => ({
      ...state,
      centerMap: action.center
    })

  ),
  on(

    PharmesActions.SetZoomMapAction, (state, action) => ({
      ...state,
      zoomMap: action.zoom
    })

  ),
  on(

    PharmesActions.AddQuantityAction, (state, action) => ({
      ...state,
      quantity: action.quantity
    })

  ),
  on(
    PharmesActions.AddPageCountLoadPharmaciesAction, (state) => ({
      ...state,
      page: [state.page[0] + 1, state.page[1] + 1]
    })
  ),
  on(
    PharmesActions.ResetPageCountLoadPharmaciesAction, (state) => ({
      ...state,
      page: [1, 1]
    })
  ),
  on(
    PharmesActions.AddOtherPageCountLoadPharmaciesAction, (state) => ({
      ...state,
      opage: [state.opage[0] + 1, state.opage[1] + 1]
    })
  ),
  on(
    PharmesActions.ResetOtherPageCountLoadPharmaciesAction, (state) => ({
      ...state,
      opage: [0, 0]
    })
  ),
  on(
    PharmesActions.AddPharmcyInCartFromListAction, (state, action) => {
      let current: BzApteka;
      if (state.listPharmacies.has(action.pharmcyId)) {
          current = state.listPharmacies.get(action.pharmcyId);
      }
      if (!state.listPharmacies.has(action.pharmcyId)) return ({...state});
      return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(action.pharmcyId, {
              ...state.listPharmacies.get(action.pharmcyId),
              isCart: true,
              isCartCurrent: !current.isCartCurrent,
              products: [...state.listPharmacies.get(action.pharmcyId).products].map(p => ({...p, isOrder: true}))
          })
        ])
      })
    }
  ),
  on(
    PharmesActions.DeletePharmcyInCartFromListAction, (state, action) => {
      if (!state.listPharmacies.has(action.pharmcy.id)) return ({...state});

      const apteka: BzApteka = state.listPharmacies.get(action.pharmcy.id);
      if (apteka.products === undefined || null) return ({...state});

      return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(action.pharmcy.id, {
            ...state.listPharmacies.get(action.pharmcy.id),
            isCart: action.isCart ? true : false,
            isCartCurrent: false,
            products: [...state.listPharmacies.get(action.pharmcy.id).products].map(p => ({...p, isOrder: false}))
          })
        ])
      })
    }
  ),
  on(
    PharmesActions.AddOneProductInCartFromListAction, (state, action) => {
      if (!state.listPharmacies.has(Array.from(action.pharmcyMap.keys())[0])) return ({...state});

      const idxp = state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0])
        .products.findIndex(pr => pr.id === Array.from(action.pharmcyMap.values())[0]);

      const length = state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]).products
        .filter(e => e.id !== Array.from(action.pharmcyMap.values())[0])
        .filter(e => !e.isOrder).length


      return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(Array.from(action.pharmcyMap.keys())[0], {
            ...state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]),
            isCart: true,
            isCartCurrent: length === 0 ? true : false,
            products: Object.assign([], {
              ...state.listPharmacies.get( Array.from(action.pharmcyMap.keys())[0] ).products,
              [idxp]: {
                ...state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]).products[idxp],
                isOrder: true
              }
            })
          })
        ])
      });
    }
  ),
  on(
    PharmesActions.DeleteOneProductInCartFromListAction, (state, action) => {
      if (!state.listPharmacies.has(Array.from(action.pharmcyMap.keys())[0])) { return ({...state}); }

      const products: BzProduct[] = state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]).products;
      if (products === null || products === undefined) { return ({...state}); }
      const idxp: number = products.findIndex((product: BzProduct) =>
          product.id === Array.from(action.pharmcyMap.values())[0]);

      const currentId: number = Array.from(action.pharmcyMap.keys())[0];

      const cartLength = Array.from(state.listPharmacies.values())
          .filter(e => e.id === currentId)[0].products
              .filter(l => l.isOrder).length;



      return ({
        ...state,
        listPharmacies: new Map([
          ...state.listPharmacies.set(Array.from(action.pharmcyMap.keys())[0], {
            ...state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]),
            isCart: cartLength === 0 ? false : true,
            isCartCurrent: false,
            products: Object.assign([], {
              ...state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]).products,
              [idxp]: {
                ...state.listPharmacies.get(Array.from(action.pharmcyMap.keys())[0]).products[idxp],
                isOrder: false
              }
            })
          })
        ])
      });
    }
  ),
  on(
    PharmesActions.DeleteAllPharmiesInCartFromListAction, (state) => {
      const newMap: Map<number, BzApteka> = new Map();
      const pharmaciesArray: BzApteka[] = [...state.listPharmacies.values()].map((pharmacie: BzApteka) => ({
        ...pharmacie, isCart: false, isCartCurrent: false, products: pharmacie.products.map((element: BzProduct) => ({...element, isOrder: false}))
      }));
      pharmaciesArray.forEach((item: BzApteka) => newMap.set(item.id, item));
      return ({...state, listPharmacies: newMap
      });
    }
  ),
  on(
    PharmesActions.ToggleProductsListInPharmacyPopupAction, (state, { value }) => {
      return ({
        ...state,
        activePopupPharmy: { ...state.activePopupPharmy, isOpenInfo: value },
      });
    }
  ),
  on(
    PharmesActions.PopupOnePharmcyForActivaMarkerAction, (state, action) => {
      return ({
        ...state,
        activePopupPharmy: action.pharmcy,
      });
    }
  ),
  on(
    PharmesActions.PopupOnePharmcyForActivaMarkerInCartAction, (state) => {
      let currentBoolean: boolean = state.activePopupPharmy.isCartCurrent;
      return ({
        ...state,
        activePopupPharmy: {
          ...state.activePopupPharmy,
          isCart: !currentBoolean,
          isCartCurrent: !currentBoolean,
          products: [...state.activePopupPharmy.products].map((item: BzProduct) => ({...item, isOrder: !currentBoolean}))
        }
      });
    }
  ),
  on(
    PharmesActions.PopupOnePharmcyForActivaMarkerInCartOneGoodAction, (state, action) => {
      if (!state.activePopupPharmy) { return({...state}); }
      const idxp = state.activePopupPharmy.products.findIndex((product: BzProduct) => product.id === action.produtID);
      let currentIsOrder: boolean = state.activePopupPharmy.products[idxp].isOrder;

      const pharmacies: BzApteka[] = Array.from(state.listPharmacies.values());
      const temp: BzApteka[] = pharmacies.filter(apteka => apteka.id === action.aptekaID);
      const currentPharma: BzApteka = temp[0];

      let result: boolean = false;

      if (currentPharma.products.every(product => product.isOrder === false)) {
        result = true;
      }

      const newProducts: BzProduct[] =  Object.assign([], {
        ...state.activePopupPharmy.products,
        [idxp]: {
          ...state.activePopupPharmy.products[idxp],
          isOrder: !currentIsOrder
        }
      });

      const isCartCurrent = newProducts.map(i => i.isOrder).every(el => el);
      const isCart = newProducts.map(i => i.isOrder).some(el => el);

      return ({
        ...state,
        activePopupPharmy: {
          ...state.activePopupPharmy,
          isCart: isCart,
          isCartCurrent: isCartCurrent,
          products: newProducts
        }
      });
    }
  ),
  on(
    PharmesActions.UnmarkIsCartIconFromPopUpAction, (state, action) => {

      if (!state.activePopupPharmy) { return({...state}); }

      const currentId: number = action.aptekaId;
      const pharmaciesArray: PharmacyMarker[] = Object.values(state.listMarkers.entities);
      const temp: PharmacyMarker[] = pharmaciesArray.filter((element: PharmacyMarker) => element.id === currentId);
      const result: PharmacyMarker = temp[0];

      return ({
        ...state,
        activePopupPharmy: {
          ...state.activePopupPharmy,
          isCart: result.cart
        }
      });
    }
  ),
  on(
    PharmesActions.AddActiveMobileFilters, (state, action) => {
      return ({
        ...state,
        activeMobileFilters: adapterActiveMobileFilters.setAll(action.keys, state.activeMobileFilters)
      });
    }
  ),
  on(
    PharmesActions.SetSeoFaqsAction, (state, { pharmacyFaq }) => {
      return ({
        ...state,
        pharmacyFaq,
      })
    }
  ),
  on(
    PharmesActions.SetSeoTableAction, (state, { seoPrice }) => {
      return ({
        ...state,
        seoPrice,
      })
    }
  ),
  on(
    PharmesActions.SetSeoPreparatPageTextAction, (state, { seoPreparat }) => {
      return ({
        ...state,
        seoPreparat,
      })
    }
  )
);

export function pharmcyReducer(state: PharmaciesPageState | undefined, action: Action): PharmaciesPageState {
  return reducer(state, action);
}
