import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import {isPlatformBrowser, isPlatformServer, PlatformLocation} from '@angular/common';
import { ScreenDetectedService } from './core/services/screen-detected.service';
import { selectVariantsChangeLocationsForPopup, selectVisibleLocationChangePopup } from '@selectors';
import { LocalizationService, LocalStorageService, SeoService } from '@core/services';
import { AppState } from '@appStates';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  PrivatePageChangeCurrentBlock,
  SetAddressAction,
  SetPopupVisibleChangeLocationAction,
  SetVariantsLocationInPopupAction
} from '@actions';
import { ICity, IOrderIdWithDate } from '@interfaces';
import { LocationStorageService } from './core/services/storage.services/location-storage-service.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { GeoLocationService } from './core/services/positioning.services/geo-location.service';
import { ECurrentOrderBlock, ListLazyComponents } from '@enums';
import { ApplicationRoutingService } from '@application-routing';
import { environment } from '@env';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { WebVitalsService } from './core/services/web-vitals.service';

const { version } = require('../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  public visibleLcationChange: Observable<boolean> = this.store.pipe(select(selectVisibleLocationChangePopup));
  public variantsLcationForPopupChange: Observable<ICity[]> = this.store.pipe(select(selectVariantsChangeLocationsForPopup));

  public primaryHeightContent: string;
  public currentLang: string;

  private readonly logoPath: string = 'https://img.zdorovi.ua/logo/logo.svg';

  constructor(
    @Inject(PLATFORM_ID) private platformId: {},
    private readonly gtmService: GoogleTagManagerService,
    private readonly ls: LocalStorageService,
    private readonly location: LocationStorageService,
    private readonly locale: LocalizationService,
    private readonly screen: ScreenDetectedService,
    private readonly platform: PlatformLocation,
    private readonly seo: SeoService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly geo: GeoLocationService,
    private readonly appRoutingService: ApplicationRoutingService,
    private readonly webVitalsService: WebVitalsService
  ) {
    this.locale.default();
    this.geo.getLabelCurrent();
    this.geo.getCityCurrent();
    this.geo.getUserTownFromNavigator();
    this.geo.getCityId();
    this.geo.getCityName();
    this.geo.getLocation();
    this.geo.setDefaultAddressHistory();
    this.geo.setDefaultAddress();
    this.onlyBrowserRun();
    this.screen.observerChangeSizeScreen();
    this.seo.addOgTypeWebsite();
    this.seo.addOgImage(this.logoPath);
    this.seo.setTwitterSummary(this.logoPath);
    this.webVitalsService.initWebVitals();
  }

  ngOnInit(): void {
    console.log('%c Версія ' + version + ' ', 'background: #2aa; color: #fff; font-size: 18px');
    // TODO BLOCK TESTING SENTRY START
    if (['dev', 'local'].includes(environment.name)) {
      // const test = {};
      // console.log((test as any).color.set());
    }
    // TODO BLOCK TESTING SENTRY END

    this.currentLang = this.locale.getLang();
    this.detectedHightContentBlock();
    this.ls.checkLocalStorageStates();
    this.initNavigationListener();
    this.checkOrderSharingParams();
  }

  ngAfterViewInit(): void {
    this.appendStyle('third-party.css');
    this.appendGoogleScript();
  }

  ngOnDestroy(): void {
    this.screen.onDestroyObserver();
  }

  selectLocation(locale: ICity): void {
    this.location.address = '';
    this.geo.setTownEn(locale.nameEn);
    this.store.dispatch(SetAddressAction({ address: null }));
    this.geo.onChangeCity(locale);
    this.store.dispatch(SetPopupVisibleChangeLocationAction({ visible: false }));
    this.store.dispatch(SetVariantsLocationInPopupAction({ variants: [] }));
  }

  closeLocationPopup(): void {
    this.store.dispatch(SetPopupVisibleChangeLocationAction({ visible: false }));
  }

  private initNavigationListener(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.seo.addOgUrl();
      /* Для страницы /search не добавляем каноникал и удаляем его если он вдруг есть */
      if (event.url.includes('/search')) {
        this.seo.deleteCanonical();
        return;
      }
      /* Для страницы /often добавляем каноникал с параметрами из урлы */
      if (event.url.includes('/often')) {
        this.seo.updateLinkCanonical(this.platform.href);
      } else {
        /* Для всех остальных страниц добавляем каноникал без параметров */
        /* И так же выкусываем страницу из урлы, если она друг есть */
        let canonicalLink = this.platform.href.split('?')[0];
        if (canonicalLink.includes('/page')) {
          canonicalLink = canonicalLink.split('/page')[0];
        }
        /* По непонятной причине, иногда в ссылке (в деве) появляется http, вместо https */
        if (canonicalLink.includes('http://')) {
          canonicalLink = canonicalLink.replace('http://', 'https://');
        }
        this.seo.updateLinkCanonical(canonicalLink);
      }
    });
  }

  private onlyBrowserRun(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.geo.setLocation();
    }
  }

  private detectedHightContentBlock(): void {
    if (this.screen.isDesktop()) {
      this.primaryHeightContent = (window.innerHeight - 85) + 'px';
    } else {
      this.primaryHeightContent = 'auto';
    }
  }

  private checkOrderSharingParams(): void {
    this.route.queryParams.pipe(
      filter((params) => params['orderId'] && params['timestamp']),
      take(1)
    ).subscribe(({ orderId, timestamp }) => {
      const orderToShare: IOrderIdWithDate = { id: orderId, timestamp: Number(timestamp) };
      if (!this.ls.isOrderExistInHistory(orderToShare.id)) {
        this.ls.setShareOrderToHistory(orderToShare);
      }

      if (this.screen.isDesktop()) {
        this.appRoutingService.navigate(ListLazyComponents.private, this.router.routerState.snapshot.url);
        this.router.navigate([], { queryParams: { orderId: null, timestamp: null }, queryParamsHandling: 'merge' });
      } else {
        this.store.dispatch(PrivatePageChangeCurrentBlock({ currentBlock: ECurrentOrderBlock.orders }));
        this.appRoutingService.navigate(ListLazyComponents.private, this.router.routerState.snapshot.url);
      }
    });
  }

  private appendStyle(name: string): void {
    const isDevEnv: boolean = environment.name !== 'prod';

    try {
      const style: HTMLLinkElement = document.createElement('link');
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = name;
      document.head.appendChild(style);
      // tslint:disable-next-line:no-console no-unused-expression
      isDevEnv && console.info('Successfully adding third-party styles');
    } catch (error) {
      console.warn('Failed to add third-party styles');
    }

  }

  private async appendGoogleScript(): Promise<void> {
    const isDevEnv: boolean = environment.name !== 'prod';

    if (isPlatformServer(this.platformId) || isDevEnv) {
      console.warn('Env without GTM');
      return;
    }

    try {
      const result: boolean = await this.gtmService.addGtmToDom();
      if (result) {
        // tslint:disable-next-line:no-console no-unused-expression
        isDevEnv && console.info('Successfully adding GTM scripts');
      } else {
        // tslint:disable-next-line:no-unused-expression
        console.warn('Failed to add GTM scripts');
      }
    } catch (error) {
      console.warn('Failed to add GTM scripts', error);
    }

  }

}
