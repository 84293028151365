import { BzApteka, FilterListPharmcies, ICity, IPharmacyFaq, IPharmacyTable, MobilePopupFilterState, PharmacyMarker } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { QuantityForOneProduct } from '@states';

export enum PharmaciesActions {
  isLoadingListPharmacies = '[Pharmacies] Is Loading List Pharmacies',
  isGetOtherCityPharmacies = '[Pharmacies] is Get Other City Pharmacies',
  IsTargetFromProductCardGetOtherCityPharmacies = '[Pharmacies] IsTargetFromProductCardGetOtherCityPharmacies',
  LoadListPharmacies = '[Pharmacies] LoadListPharmacies',
  ClearListPharmacies = '[Pharmacies] ClearListPharmacies',
  SwitchInfoOnePharmcy = '[Pharmacies] SwitchInfoOnePharmcy',
  PopupOnePharmcyForActivaMarker = '[Pharmacies] PopupOnePharmcyForActivaMarker',
  PopupOnePharmcyForActivaMarkerInCart = '[Pharmacies] PopupOnePharmcyForActivaMarkerInCart',
  PopupOnePharmcyForActivaMarkerInCartOneGood = '[Pharmacies] PopupOnePharmcyForActivaMarkerInCartOneGood',
  SwitchMobilePageMap = '[Pharmacies] SwitchMobilePageMap',
  LoadListMarkers = '[Pharmacies] LoadListMarkers',
  ToAddCartMarker = '[Pharmacies] UpdateMarker',
  DisableCartMarker = '[Pharmacies] DisableCartMarker',
  SelectedCartMarker = '[Pharmacies] SelectedCartMarker',
  SetCenterMap = '[Pharmacies] SetCenterMap',
  SetZoomMap = '[Pharmacies] SetZoomMap',
  AddQuantity = '[Pharmacies] Add Quantity',
  ResetPageCountLoadPharmacies = '[Pharmacies] ResetPageCountLoadData',
  ResetOtherPageCountLoadPharmacies = '[Pharmacies] ResetOtherPageCountLoadPharmacies',
  AddPageCountLoadPharmacies = '[Pharmacies] AddPageCountLoadData',
  AddOtherPageCountLoadPharmacies = '[Pharmacies] AddOtherPageCountLoadPharmacies',
  AddPharmcyInCartFromList = '[Pharmacies] AddPharmcyInCartFromList',
  RunEffectDeletePharmcyInCartFromList = '[Pharmacies] RunEffectDeletePharmcyInCartFromList',
  DeletePharmcyInCartFromList = '[Pharmacies] DeletePharmcyInCartFromList',
  AddOneProductInCartFromList = '[Pharmacies] AddOneProductInCartFromList',
  DeleteOneProductInCartFromList = '[Pharmacies] DeleteOneProductInCartFromList',
  DeleteAllPharmiesInCartFromList = '[Pharmacies] DeleteAllPharmiesInCartFromList',
  AddActiveMobileFilters = '[Pharmacies] AddActiveMobileFilters',
  UnmarkIsCartIconFromPopUp = '[Pharmacies] UnmarkIsCartIconFromPopUp',
  DeleteToTopInListPharmacies = '[Pharmacies] Delete To Top In List Pharmacies',
  ClearAllToTopInListPharmacies = '[Pharmacies] Clear All To Top In List Pharmacies',
  setTotalPharmaciesLength = '[Pharmacies] Set Total Pharmacies Number',
  setTotalOtherPharmaciesLength = '[Pharmacies] Set Total Other Pharmacies Length',
  setSeoFaqs = '[Pharmacies] Set Seo Faq Data',
  setSeoTable = '[Pharmacies] Set Seo Table Data',
  ChangePharmaciesCity = '[Pharmacies] Change Pharmacies City',
  ToggleProductsListInPharmacyPopup = '[Pharmacies] Toggle Products List In Popup',
  ResetFiltersFromMobilePharmaciesPopup = '[Pharmacies] Reset Filters From Mobile Pharmacies Popup',
  SetFiltersFromMobilePharmaciesPopup = '[Pharmacies] Set Filters From Mobile Pharmacies Popup',
  SetSeoPreparatPageText = '[Pharmacies] Set Seo Preparat Page Text',
}

export const isGetOtherCityPharmaciesAction = createAction(
  PharmaciesActions.isGetOtherCityPharmacies,
  props<{ other: boolean }>()
);

export const IsTargetFromProductCardGetOtherCityPharmaciesAction = createAction(
  PharmaciesActions.IsTargetFromProductCardGetOtherCityPharmacies,
  props<{ other: boolean }>()
);

export const isLoadingListPharmaciesAction = createAction(
  PharmaciesActions.isLoadingListPharmacies,
  props<{ loading: boolean }>()
);

export const SwitchMobilePageMapAction = createAction(
  PharmaciesActions.SwitchMobilePageMap,
  props<{ active: boolean }>()
);

export const DeleteToTopInListPharmaciesAction = createAction(
  PharmaciesActions.DeleteToTopInListPharmacies,
  props<{ id: number }>()
);

export const ClearAllToTopInListPharmaciesAction = createAction(
  PharmaciesActions.ClearAllToTopInListPharmacies
);

export const LoadListPharmaciesAction = createAction(
  PharmaciesActions.LoadListPharmacies,
  props<{ pharmacies: Map<number, BzApteka> }>()
);

export const setTotalPharmaciesLengthAction = createAction(
  PharmaciesActions.setTotalPharmaciesLength,
  props<{ total: boolean }>()
);

export const setTotalOtherPharmaciesLengthAction = createAction(
  PharmaciesActions.setTotalOtherPharmaciesLength,
  props<{ total: boolean }>()
);

export const ClearListPharmaciesAction = createAction(
  PharmaciesActions.ClearListPharmacies
);

export const LoadListMarkersAction = createAction(
  PharmaciesActions.LoadListMarkers,
  props<{ markers: PharmacyMarker[] }>()
);

export const DisableCartMarkersAction = createAction(
  PharmaciesActions.DisableCartMarker
);

export const SelectedCartMarkerAction = createAction(
  PharmaciesActions.SelectedCartMarker,
  props<{ id: number }>()
);

export const SetCenterMapAction = createAction(
  PharmaciesActions.SetCenterMap,
  props<{ center: number[] }>()
);

export const SetZoomMapAction = createAction(
  PharmaciesActions.SetZoomMap,
  props<{ zoom: number }>()
);

export const AddQuantityAction = createAction(
  PharmaciesActions.AddQuantity,
  props<{ quantity: QuantityForOneProduct }>()
);

export const AddPageCountLoadPharmaciesAction = createAction(
  PharmaciesActions.AddPageCountLoadPharmacies
);

export const ResetPageCountLoadPharmaciesAction = createAction(
  PharmaciesActions.ResetPageCountLoadPharmacies
);

export const AddOtherPageCountLoadPharmaciesAction = createAction(
  PharmaciesActions.AddOtherPageCountLoadPharmacies
);

export const ResetOtherPageCountLoadPharmaciesAction = createAction(
  PharmaciesActions.ResetOtherPageCountLoadPharmacies
);

export const AddPharmcyInCartFromListAction = createAction(
  PharmaciesActions.AddPharmcyInCartFromList,
  props<{ pharmcyId: number }>()
);

export const RunEffectDeletePharmcyInCartFromListAction = createAction(
  PharmaciesActions.RunEffectDeletePharmcyInCartFromList,
  props<{ pharmcy: BzApteka }>()
);

export const DeletePharmcyInCartFromListAction = createAction(
  PharmaciesActions.DeletePharmcyInCartFromList,
  props<{ pharmcy: BzApteka, isCart?: boolean }>()
);

export const AddOneProductInCartFromListAction = createAction(
  PharmaciesActions.AddOneProductInCartFromList,
  props<{ pharmcyMap: Map<number, number> }>()
);

export const DeleteOneProductInCartFromListAction = createAction(
  PharmaciesActions.DeleteOneProductInCartFromList,
  props<{ pharmcyMap: Map<number, number> }>()
);

export const DeleteAllPharmiesInCartFromListAction = createAction(
  PharmaciesActions.DeleteAllPharmiesInCartFromList
);

export const SwitchInfoOnePharmcyAction = createAction(
  PharmaciesActions.SwitchInfoOnePharmcy,
  props<{ id: number, value: boolean }>()
);

export const PopupOnePharmcyForActivaMarkerAction = createAction(
  PharmaciesActions.PopupOnePharmcyForActivaMarker,
  props<{ pharmcy: BzApteka }>()
);

export const ToggleProductsListInPharmacyPopupAction = createAction(
  PharmaciesActions.ToggleProductsListInPharmacyPopup,
  props<{ value: boolean }>()
);

export const PopupOnePharmcyForActivaMarkerInCartAction = createAction(
  PharmaciesActions.PopupOnePharmcyForActivaMarkerInCart
);

export const PopupOnePharmcyForActivaMarkerInCartOneGoodAction = createAction(
  PharmaciesActions.PopupOnePharmcyForActivaMarkerInCartOneGood,
  props<{ produtID: number, aptekaID: number }>()
);

export const UnmarkIsCartIconFromPopUpAction = createAction(
  PharmaciesActions.UnmarkIsCartIconFromPopUp,
  props<{ aptekaId: number }>()
);

export const AddActiveMobileFilters = createAction(
  PharmaciesActions.AddActiveMobileFilters,
  props<{ keys: string[] }>()
);

export const UpdateCartMarkerAction = createAction(
  PharmaciesActions.ToAddCartMarker,
  props<{ markerID: number, value: boolean }>()
);

export const SetSeoFaqsAction = createAction(
  PharmaciesActions.setSeoFaqs,
  props<{ pharmacyFaq: IPharmacyFaq }>()
);

export const SetSeoTableAction = createAction(
  PharmaciesActions.setSeoTable,
  props<{ seoPrice: IPharmacyTable }>()
);

export const SetSeoPreparatPageTextAction = createAction(
  PharmaciesActions.SetSeoPreparatPageText,
  props<{ seoPreparat: string }>()
);

export const ChangePharmaciesCityAction = createAction(
  PharmaciesActions.ChangePharmaciesCity,
  props<{ newCity: ICity }>()
);

export const ResetFiltersFromMobilePharmaciesPopup = createAction(
  PharmaciesActions.ResetFiltersFromMobilePharmaciesPopup
);

export const SetFiltersFromMobilePharmaciesPopup = createAction(
  PharmaciesActions.SetFiltersFromMobilePharmaciesPopup,
  props<{ state: Map<string, FilterListPharmcies> }>()
);