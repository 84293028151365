import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { onLCP, onINP, onCLS, onFCP, onTTFB, CLSMetric, INPMetric, LCPMetric, FCPMetric, TTFBMetric } from 'web-vitals';

declare var gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class WebVitalsService {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    initWebVitals(): void {
        if (isPlatformBrowser(this.platformId)) {
            onCLS(this.sendToAnalytics);
            onINP(this.sendToAnalytics);
            onLCP(this.sendToAnalytics);
            onFCP(this.sendToAnalytics);
            onTTFB(this.sendToAnalytics);
        }
    }

    private sendToAnalytics(metric: CLSMetric | INPMetric | LCPMetric | FCPMetric | TTFBMetric): void {
        gtag('event', metric.name, {
            event_category: 'Web Vitals',
            value: Math.round(metric.value),
            event_label: metric.id,
            non_interaction: true,
        });
    }
}