import { BzApteka, IPharmacyFaq, IPharmacyTable, PharmacyMarker } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const adapterListMarkers: EntityAdapter<PharmacyMarker> = createEntityAdapter<PharmacyMarker>();

export const adapterActiveMobileFilters: EntityAdapter<string> = createEntityAdapter<string>({
    selectId: (filter: string) => filter
});
  
export interface PharmaciesPageState {
    isActiveMobilePageMap: boolean;
    isLoadingListPharmacies: boolean;
    isGetOtherCityPharmacies: boolean;
    isTargetFromProductCardGetOtherCityPharmacies: boolean;
    listPharmacies: Map<number, BzApteka>;
    activePopupPharmy: BzApteka;
    page: number[];
    opage: number[];
    quantity: QuantityForOneProduct;
    centerMap: number[];
    listMarkers: EntityState<PharmacyMarker>;
    zoomMap: number;
    activeMobileFilters: EntityState<string>;
    totalPharmaciesNumber: boolean;
    totalOtherPharmacies: boolean;
    pharmacyFaq: IPharmacyFaq;
    seoPrice: IPharmacyTable;
    seoPreparat: string;
}

export interface QuantityForOneProduct {
    qty?: number;
    packageContentNum?: number;
    blisterNum?: number;
    packageNum?: number;
    balanceNum?: number;
    packageContentType?: string;
}

export const initialStatePharmaciesPage: PharmaciesPageState = {
    isActiveMobilePageMap: false,
    isLoadingListPharmacies: false,
    isGetOtherCityPharmacies: false,
    isTargetFromProductCardGetOtherCityPharmacies: false,
    listPharmacies: new Map(),
    activePopupPharmy: null,
    page: [1, 1],
    opage: [0, 0],
    quantity: {
        qty: 1,
        packageContentNum: 0,
        balanceNum: 1,
        packageContentType: 'Блистер'
    },
    listMarkers: adapterListMarkers.getInitialState([]),
    centerMap: [],
    zoomMap: 11,
    activeMobileFilters: adapterActiveMobileFilters.getInitialState([]),
    totalPharmaciesNumber: false,
    totalOtherPharmacies: false,
    pharmacyFaq: null,
    seoPrice: null,
    seoPreparat: '',
};
